import * as React from 'react';

import { Footer, GoogleNoIndex, NavMenu, PageTitle } from '../components/shared';

import { TaxProtestProcess } from '../components/home';
import { ThankYou } from '../components/landing';

const ThanksForYourRequestPage: React.FunctionComponent = () => {
  return (
    <>
      <PageTitle />
      <GoogleNoIndex />
      <NavMenu solidColor notFixed />
      <ThankYou />
      <TaxProtestProcess />
      <Footer />
    </>
  );
};

export default ThanksForYourRequestPage;
